<template>
  <ProductCardCarouselSkeleton
    v-if="pending"
    class="mb-32"
    :with-title="!!title"
  />
  <section v-else-if="products.length > 0" class="mb-32">
    <BlockTitle :subtitle="subtitle" :title="title" />
    <ProductCardCarousel
      :products="products"
      :tags="[i18n(translations.recommendationTag)]"
      :tracking="{ ...trackingData, provider: 'attraqt' }"
      :use-client-side-navigation="useClientSideNavigation"
      v-on="options.withAddToCart ? { addToCart: addToCartHandler } : {}"
    />
  </section>
</template>

<script lang="ts" setup>
import { watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import type { ExtraTrackingData } from '../composables/useProductTracking'
import { useRecommendedProducts } from '../composables/useRecommendation'

import BlockTitle from './BlockTitle.vue'
import ProductCardCarousel from './ProductCardCarousel/ProductCardCarousel.vue'
import ProductCardCarouselSkeleton from './ProductCardCarousel/ProductCardCarouselSkeleton.vue'
import translations from './Recommendation.translations'

const props = withDefaults(
  defineProps<{
    title?: string
    subtitle?: string
    recommendationQuery: {
      limit: number
      personalisation?: boolean
    } & (
      | {
          scope: string
          scopeId: string
          category: string
        }
      | {
          widgetId: string
        }
    )
    options?: {
      withCrossedPrice?: boolean
      withStartingFrom?: boolean
      withGrade?: boolean
      withAddToCart?: boolean
    }
    trackingData?: ExtraTrackingData
    useClientSideNavigation?: boolean
  }>(),
  {
    title: undefined,
    subtitle: undefined,
    options: () => ({
      withCrossedPrice: false,
      withStartingFrom: true,
      withGrade: false,
      withAddToCart: false,
    }),
    trackingData: () => ({}),
    useClientSideNavigation: true,
  },
)

const { products, pending, error } = useRecommendedProducts(
  props.recommendationQuery,
)

const emit = defineEmits(['error', 'loaded', 'refresh'])

const i18n = useI18n()

watch(products, () => {
  if (products.value.length === 0) {
    emit('error', null)
  } else {
    emit('loaded', products.value)
  }
})

watch(error, () => {
  if (error !== null) {
    emit('error', error)
  }
})

const { openSuccessToast, openErrorToast } = useTheToast()

function addToCartHandler({
  status,
  product,
}: {
  status: 'success' | 'error'
  product: { listingId: string }
}) {
  if (status === 'success') {
    openSuccessToast({
      title: i18n(translations.toastSuccessTitle),
      content: i18n(translations.toastSuccessMessage),
    })
    emit('refresh', product.listingId)
  }
  if (status === 'error') {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorMessage),
    })
  }
}
</script>
